
.react-calendar {
    width: 300px;
    max-width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .react-calendar__tile--active {
    background-color: #4CAF50 !important;
    color: #ffffff !important;
  }
  
  .react-calendar__navigation__label {
    color: #333333;
  }
  
  .react-calendar__navigation button {
    color: #4CAF50;
  }
  
  .react-calendar__tile {
    border: none !important;
    padding: 8px;
  }
  
  .react-calendar__tile--now {
    background: #f0f0f0 !important;
  }
  
  .react-calendar__tile--hover {
    background-color: #e0e0e0;
  }
  
  .react-calendar__tile--hasActive {
    background-color: #4CAF50 !important;
    color: #ffffff !important;
  }
  
  
  .selected-date {
    font-size: 18px;
    font-weight: bold;
    color: #4CAF50;
  }
  
  
  .tasks-list {
    list-style-type: none;
    padding: 0;
  }
  
  .tasks-list li {
    margin-bottom: 8px;
  }


.container {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    margin: 50px; 
  }
  
  .calendar-container {
    margin-right: 20px; 
    padding-right: 10%;
  }
  
  
  
  