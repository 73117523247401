.content-container {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    width: 100%;
  }
  
  .content-list-container {
    grid-column: 1 / 2;
  }
  
  .designer-name-container {
    grid-column: 2 / 3;
  }
  
  .checkbox-container {
    grid-column: 3 / 4;
  }
  
  .content-list {
    list-style-type: none;
    padding: 0;
    
  }
  
  .content-item {
    margin-bottom: 20px;
    
  }
  
  .checkbox-label {
    position: relative;
    display: inline-block;
    margin-left: 10px; 
    margin-bottom: 40px;
  }
  
  .designer-name-item {
    margin-bottom: 20px; /* Adjust as needed */
  }
  
  .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    
    
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    column-gap: 15px;
    background-color: #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .checkbox-label input:checked ~ .checkmark:after {
    content: '✔️'; /* Add a tick mark symbol */
    color: white;
    
  }
  